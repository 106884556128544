import InputField from "components/fields/InputField";
import authImg from "assets/img/auth/logo-dark.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import { useState } from "react";

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    const identifier = e.target.elements.identifier.value;
    const password = e.target.elements.password.value;

    try {
      setLoading(true);
      const response = await axios.post(
        "https://api-sehatmurnisejahtera.vercel.app/api/auth/login",
        {
          identifier,
          password,
        }
      );

      if (response.data) {
        toast.success(response.data.message);
        localStorage.setItem("user_token", response.data.token);
        navigate("/dashboard/home");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Welcome! Please Sign In to access Marketing Sehat Murni Sejahtera.
        </p>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        <form onSubmit={handleLogin}>
          {/* Email */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email / Username*"
            placeholder="user@mail.com or user"
            id="identifier"
            type="text"
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
          />
          <button
            className="linear bg- mt-2 w-full rounded-xl bg-blueSecondary py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            disabled={loading}
          >
            {loading ? (
              <ClipLoader size={20} color={"#ffffff"} loading={loading} />
            ) : (
              "Sign In"
            )}
          </button>
          <div className="mt-6 flex flex-col items-center justify-center">
            <div className="text-black">
              <p>Powered by </p>
            </div>
            <img src={authImg} alt="Codenito" className="w-[20%]" />
          </div>
        </form>
      </div>
    </div>
  );
}
