import React, { useState, useEffect } from "react";

import { ClipLoader } from "react-spinners";
import axios from "axios";

function Table({ isAdmin, user_id, submitSuccess, setSubmitSuccess, loading }) {
  const [userData, setUserData] = useState([]);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSort, setSelectedSort] = useState("Sort by");
  const [selectedFilter, setSelectedFilter] = useState("Filter by Name");
  const [userNames, setUserNames] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [selectedImage, setSelectedImage] = useState("");
  const itemsPerPage = 10;

  const fetchData = async () => {
    try {
      if (search.length >= 3 || search.length === 0) {
        const response = await axios.post(
          "https://api-sehatmurnisejahtera.vercel.app/api/attendance/all",
          {
            isAdmin,
            user_id,
            page: currentPage,
            itemsPerPage,
            sortOrder,
            searchName: search,
          }
        );

        setUserData(response.data.data.results);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        const names = response.data.data.results.map((user) => user.name);
        setUserNames(names);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (isAdmin || user_id) {
      fetchData();
    }
    if (submitSuccess) {
      fetchData();
      setSubmitSuccess(false);
    }
  }, [currentPage, sortOrder, search, isAdmin, user_id, submitSuccess]);

  const handleSortOrderChange = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSortSelect = (e) => {
    setSelectedSort(e.target.value);
    setSortOrder(e.target.value === "asc" ? "asc" : "desc");
  };

  const handleFilterSelect = (e) => {
    setSelectedFilter(e.target.value);
  };

  const handlePaginationClick = async (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      await fetchData();
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString([], {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="bg-white">
      <div className="relative overflow-x-auto p-4 shadow-md sm:rounded-lg">
        <h1 className="py-4 text-2xl font-semibold">Attendance List</h1>
        <div className="flex-column flex flex-wrap items-center justify-between space-y-4 bg-white pb-4 dark:bg-gray-900 md:flex-row md:space-y-0">
          <div className="flex flex-row gap-4">
            <select
              id="sort"
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              value={selectedSort}
              onChange={handleSortSelect}
            >
              <option value="Sort by">Sort by</option>
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </select>
          </div>

          {/* Search input */}
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="rtl:inset-r-0 pointer-events-none absolute inset-y-0 flex items-center ps-3 start-0">
              <svg
                className="h-4 w-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search-users"
              className="block w-80 rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 ps-10 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              placeholder="Search for users"
              value={search}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
          <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="p-4">
                No
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Time
              </th>
              <th scope="col" className="px-6 py-3">
                Location
              </th>
              <th scope="col" className="px-6 py-3">
                Photo
              </th>
              <th scope="col" className="px-6 py-3">
                Signature
              </th>
              <th scope="col" className="px-6 py-3">
                Description
              </th>
              <th scope="col" className="px-6 py-3">
                Products Offered
              </th>
              <th scope="col" className="px-6 py-3">
                Google Maps
              </th>
            </tr>
          </thead>
          <tbody>
            {loading === true ? (
              <tr>
                <td colSpan="8" className="p-8 text-center">
                  <ClipLoader size={35} color={"#123abc"} />
                </td>
              </tr>
            ) : userData.length === 0 ? (
              <tr>
                <td
                  colSpan="8"
                  className="p-8 text-center text-gray-500 dark:text-gray-400"
                >
                  No data available.
                </td>
              </tr>
            ) : (
              userData.map((user, index) => (
                <tr
                  key={index}
                  className="border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  <td className="w-4 p-4">
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>

                  <td className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white">
                    {user.name}
                  </td>
                  <td className="px-6 py-4">{formatDate(user.date)}</td>
                  <td className="px-6 py-4">{user.checkin_time}</td>
                  <td className="px-6 py-4">{user.address}</td>
                  <td className="px-6 py-4">
                    {user.checkin_photo ? (
                      <img
                        src={user.checkin_photo}
                        alt="Photo"
                        className="h-16 w-16 cursor-pointer rounded-full object-cover"
                        onClick={() => openModal(user.checkin_photo)}
                      />
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                  <td className="px-6 py-4">
                    {user.checkin_signature ? (
                      <img
                        src={user.checkin_signature}
                        alt="Signature"
                        className="h-16 w-16 cursor-pointer rounded-full object-cover"
                        onClick={() => openModal(user.checkin_signature)}
                      />
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                  <td className="px-6 py-4">{user.description}</td>
                  <td className="px-6 py-4">{user.product_desc}</td>
                  <td className="px-6 py-4">
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${user.location_lat},${user.location_long}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      View on Map
                    </a>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <nav
          className="flex-column flex flex-wrap items-center justify-between pt-4 md:flex-row"
          aria-label="Table navigation"
        >
          <span className="mb-4 block w-full text-sm font-normal text-gray-500 dark:text-gray-400 md:mb-0 md:inline md:w-auto">
            Showing{" "}
            <span className="font-semibold text-gray-900 dark:text-white">
              {(currentPage - 1) * itemsPerPage + 1} -{" "}
              {Math.min(currentPage * itemsPerPage, totalCount)}{" "}
            </span>
            of{" "}
            <span className="font-semibold text-gray-900 dark:text-white">
              {totalCount}
            </span>
          </span>
          <ul className="inline-flex h-8 -space-x-px text-sm rtl:space-x-reverse">
            <li>
              <a
                href="#"
                className="flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 ms-0 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={() => handlePaginationClick(currentPage - 1)}
              >
                Previous
              </a>
            </li>

            {Array.from({ length: totalPages }).map((_, index) => (
              <li key={index}>
                <a
                  href="#"
                  className={`flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 ${
                    index + 1 === currentPage
                      ? "bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                      : "hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  }`}
                  onClick={() => handlePaginationClick(index + 1)}
                >
                  {index + 1}
                </a>
              </li>
            ))}

            <li>
              <a
                href="#"
                className="flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={() => handlePaginationClick(currentPage + 1)}
              >
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {modalIsOpen && (
        <div
          className="overlay fixed inset-0  top-0 left-0 z-50 flex  h-full w-full items-center justify-center bg-gray-500 bg-opacity-70"
          onClick={closeModal}
        >
          <img
            src={selectedImage}
            alt="Enlarged Checkin Photo"
            className="max-h-full max-w-full p-4"
          />
        </div>
      )}
    </div>
  );
}

export default Table;
