import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";

const ComplexTable = ({
  data,
  currentPage,
  itemsPerPage,
  totalCount,
  handlePaginationClick,
  totalPages,
  fetchDataFromAPI,
  loading,
  sortOrder,
  selectedSort,
  handleSortSelect,
  search,
  handleSearchChange,
}) => {
  const [editUser, setEditUser] = useState(null);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [deleteUserEmail, setDeleteUserEmail] = useState(null);
  const [deleteUser, setDeleteUser] = useState(null);
  const [actionloading, setActionLoading] = useState(false);

  const togglePasswordVisibility = (userId) => {
    setShowPassword((prev) => ({ ...prev, [userId]: !prev[userId] }));
  };

  const openEditModal = (user) => {
    setEditUser(user);
  };

  const openDeleteModal = (user) => {
    setDeleteUser(user);
  };

  const closeModal = () => {
    setEditUser(null);
    setDeleteUser(null);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();

    try {
      setActionLoading(true);
      const response = await axios.put(
        "https://api-sehatmurnisejahtera.vercel.app/api/auth/update/user",
        editUser
      );
      toast.success(response.data.message);
      closeModal();
      await fetchDataFromAPI();
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setActionLoading(false);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      setActionLoading(true);
      const response = await axios.delete(
        `https://api-sehatmurnisejahtera.vercel.app/api/auth/delete/user/${deleteUser.user_id}`
      );
      toast.success(response.data.message);
      closeModal();
      await fetchDataFromAPI();
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div className="bg-white">
      <div className="relative overflow-x-auto p-4 shadow-md sm:rounded-lg">
        <h1 className="py-4 text-2xl font-semibold">Account List</h1>
        <div className="flex-column flex flex-wrap items-center justify-between space-y-4 bg-white pb-4 dark:bg-gray-900 md:flex-row md:space-y-0">
          <div className="flex flex-row gap-4">
            <select
              id="sort"
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              value={selectedSort}
              onChange={handleSortSelect}
            >
              <option value="asc">Sort by</option>
              <option value="desc">Name Z-A</option>
              <option value="asc">Name A-Z</option>
            </select>
          </div>

          {/* Search input */}
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="rtl:inset-r-0 pointer-events-none absolute inset-y-0 flex items-center ps-3 start-0">
              <svg
                className="h-4 w-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search-users"
              className="block w-80 rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 ps-10 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              placeholder="Search for users"
              value={search}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
          <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="p-4">
                No
              </th>
              <th scope="col" className="px-6 py-3">
                User Id
              </th>
              <th scope="col" className="px-6 py-3">
                Username
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Password
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {loading === true ? (
              <tr>
                <td colSpan="8" className="p-8 text-center">
                  <ClipLoader size={35} color={"#123abc"} />
                </td>
              </tr>
            ) : data.length === 0 ? (
              <tr>
                <td
                  colSpan="8"
                  className="p-8 text-center text-gray-500 dark:text-gray-400"
                >
                  No data available.
                </td>
              </tr>
            ) : (
              data.map((user, index) => (
                <tr
                  key={index}
                  className="border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  <td className="w-4 p-4">
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>

                  <td className="px-6 py-4">{user.user_id}</td>
                  <td className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white">
                    {user.name}
                  </td>
                  <td className="px-6 py-4">{user.email}</td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      {showPassword[user.user_id] ? (
                        <span className="mr-2">{user.password}</span>
                      ) : (
                        <span className="mr-2">
                          {"*".repeat(user.password.length)}
                        </span>
                      )}
                      <button
                        type="button"
                        onClick={() => togglePasswordVisibility(user.user_id)}
                        className="p-2 focus:outline-none"
                      >
                        {showPassword[user.user_id] ? (
                          <FaEyeSlash className="text-gray-500" />
                        ) : (
                          <FaEye className="text-gray-500" />
                        )}
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    {user.isAdmin === 1 ? "Admin" : "User"}
                  </td>
                  <td className="px-6 py-4">
                    {/* Tombol Edit */}
                    <button
                      className="mr-2 text-blue-500 hover:underline"
                      onClick={() => openEditModal(user)}
                    >
                      Edit
                    </button>
                    {/* Tombol Delete */}
                    <button
                      className="text-red-500 hover:underline"
                      onClick={() => openDeleteModal(user)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <nav
          className="flex-column flex flex-wrap items-center justify-between pt-4 md:flex-row"
          aria-label="Table navigation"
        >
          <span className="mb-4 block w-full text-sm font-normal text-gray-500 dark:text-gray-400 md:mb-0 md:inline md:w-auto">
            Showing{" "}
            <span className="font-semibold text-gray-900 dark:text-white">
              {(currentPage - 1) * itemsPerPage + 1} -{" "}
              {Math.min(currentPage * itemsPerPage, totalCount)}{" "}
            </span>
            of{" "}
            <span className="font-semibold text-gray-900 dark:text-white">
              {totalCount}
            </span>
          </span>
          <ul className="inline-flex h-8 -space-x-px text-sm rtl:space-x-reverse">
            <li>
              <a
                href="#"
                className="flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 ms-0 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={() => handlePaginationClick(currentPage - 1)}
              >
                Previous
              </a>
            </li>

            {Array.from({ length: totalPages }).map((_, index) => (
              <li key={index}>
                <a
                  href="#"
                  className={`flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 ${
                    index + 1 === currentPage
                      ? "bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                      : "hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  }`}
                  onClick={() => handlePaginationClick(index + 1)}
                >
                  {index + 1}
                </a>
              </li>
            ))}

            <li>
              <a
                href="#"
                className="flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={() => handlePaginationClick(currentPage + 1)}
              >
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {editUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
            {/* Formulir Edit */}
            <form onSubmit={handleEditSubmit}>
              <h1 className="mb-4 text-xl font-semibold">Edit User</h1>
              <div className="mb-4">
                <label
                  htmlFor="user_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  User ID
                </label>
                <input
                  type="text"
                  id="user_id"
                  name="user_id"
                  value={editUser.user_id}
                  readOnly
                  className="mt-1 block w-full rounded-md border border-gray-300 p-2.5 focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={editUser.name}
                  onChange={(e) =>
                    setEditUser({ ...editUser, name: e.target.value })
                  }
                  className="mt-1 block w-full rounded-md border border-gray-300 p-2.5 focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={editUser.email}
                  onChange={(e) =>
                    setEditUser({ ...editUser, email: e.target.value })
                  }
                  className="mt-1 block w-full rounded-md border border-gray-300 p-2.5 focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="flex items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={editUser.password}
                    onChange={(e) =>
                      setEditUser({ ...editUser, password: e.target.value })
                    }
                    className="mt-1 block w-full rounded-md border border-gray-300 p-2.5 focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="ml-2 p-2 focus:outline-none"
                  >
                    {showPassword ? (
                      <FaEyeSlash className="text-gray-500" />
                    ) : (
                      <FaEye className="text-gray-500" />
                    )}
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray-700"
                >
                  Role
                </label>
                <select
                  id="role"
                  name="isAdmin"
                  value={editUser.isAdmin}
                  onChange={(e) =>
                    setEditUser({
                      ...editUser,
                      isAdmin: parseInt(e.target.value, 10),
                    })
                  }
                  className="mt-1 mb-4 block w-full rounded-md border border-gray-300 p-2.5 focus:border-blue-500 focus:ring-blue-500"
                  required
                >
                  <option value="1">Admin</option>
                  <option value="0">User</option>
                </select>
              </div>

              <div className="flex space-x-4 pt-6">
                <button
                  type="submit"
                  className="rounded-lg bg-brand-500 px-4 py-2 text-white"
                >
                  {actionloading ? (
                    <ClipLoader
                      size={20}
                      color={"#ffffff"}
                      loading={actionloading}
                    />
                  ) : (
                    "Save Changes"
                  )}
                </button>
                <button
                  type="button"
                  className="rounded-lg bg-gray-200  px-4 py-2 text-gray-800"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Modal Delete */}
      {deleteUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
            <div>
              <h1 className="mb-4 text-xl font-semibold">Delete User</h1>
              <p className="mb-4 text-gray-700">
                Are you sure you want to delete {deleteUser.email}?
              </p>
              <button
                type="button"
                className="mr-2 rounded-lg bg-red-600 px-4 py-2 text-white"
                onClick={handleDeleteConfirm}
              >
                {actionloading ? (
                  <ClipLoader
                    size={20}
                    color={"#ffffff"}
                    loading={actionloading}
                  />
                ) : (
                  "Yes, I'm sure"
                )}
              </button>
              <button
                type="button"
                className="rounded-lg bg-gray-300 px-4 py-2 text-gray-800"
                onClick={closeModal}
              >
                No, cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComplexTable;
