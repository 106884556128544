import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const AuthSlice = createSlice({
  name: "Auth",
  initialState: {
    Auth: [],
  },
  reducers: {
    setAuth: (state, action) => {
      state.Auth = action.payload;
    },
  },
});

export const { setAuth } = AuthSlice.actions;

export default AuthSlice.reducer;

export function user() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("user_token");
      if (token) {
        let response = await axios.post(
          `https://api-sehatmurnisejahtera.vercel.app/api/auth/user`,
          {
            token: token,
          }
        );
        dispatch(setAuth(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
}
