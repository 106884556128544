import ComplexTable from "../tables/components/ComplexTable";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

import "react-toastify/dist/ReactToastify.css";

const ProfileOverview = () => {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSort, setSelectedSort] = useState("Sort by");
  const [selectedFilter, setSelectedFilter] = useState("Filter by Name");
  const [userNames, setUserNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    role: 0, // Assuming 0 is the default role, update as needed
  });
  const itemsPerPage = 10;

  const fetchDataFromAPI = async () => {
    try {
      if (search.length >= 3 || search.length === 0) {
        setLoading(true);
        const response = await axios.post(
          "https://api-sehatmurnisejahtera.vercel.app/api/data/user",
          {
            page: currentPage,
            itemsPerPage,
            sortOrder,
            searchName: search,
          }
        );

        if (response.data) {
          setUserData(response.data.data.results);
          setTotalPages(response.data.data.totalPages);
          setTotalCount(response.data.data.totalCount);
        }
      }
    } catch (error) {
      console.error("Error fetching data from API:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSortOrderChange = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    // setSearch(value);
  };

  const handleSortSelect = (e) => {
    setSelectedSort(e.target.value);
    setSortOrder(e.target.value === "asc" ? "asc" : "desc");
  };

  const handleFilterSelect = (e) => {
    setSelectedFilter(e.target.value);
  };

  const handlePaginationClick = async (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      await fetchDataFromAPI();
    }
  };

  const openAddUserModal = () => {
    setAddUserModalOpen(true);
  };

  const closeAddUserModal = () => {
    setAddUserModalOpen(false);
  };

  const handleAddUserSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(
        "https://api-sehatmurnisejahtera.vercel.app/api/auth/register",
        newUser
      );
      toast.success(response.data.message);
      closeAddUserModal();
      setNewUser({
        name: "",
        email: "",
        password: "",
        role: 0,
      });
      await fetchDataFromAPI();
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error adding user:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [currentPage, sortOrder, search]);
  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        {" "}
        <button
          className="rounded-lg bg-blueSecondary px-4 py-2 text-white"
          onClick={openAddUserModal}
        >
          Add User
        </button>
      </div>
      <div className="ggrid-cols-12 grid h-full">
        <div className="z-0">
          <ComplexTable
            data={userData}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalCount={totalCount}
            totalPages={totalPages}
            handlePaginationClick={handlePaginationClick}
            fetchDataFromAPI={fetchDataFromAPI}
            loading={loading}
            sortOrder={sortOrder}
            selectedSort={selectedSort}
            handleSortSelect={handleSortSelect}
            search={search}
            handleSearchChange={handleSearchChange}
          />
        </div>
      </div>
      {addUserModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
            {/* Form for adding a new user */}
            <form onSubmit={handleAddUserSubmit}>
              <h1 className="mb-4 text-xl font-semibold">Add User</h1>
              {/* Name field */}
              <div className="mb-4">
                <label
                  htmlFor="newUserName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="newUserName"
                  name="newUserName"
                  value={newUser.name}
                  onChange={(e) =>
                    setNewUser({ ...newUser, name: e.target.value })
                  }
                  className="mt-1 block w-full rounded-md border border-gray-300 p-2.5 focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              {/* Email field */}
              <div className="mb-4">
                <label
                  htmlFor="newUserEmail"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="newUserEmail"
                  name="newUserEmail"
                  value={newUser.email}
                  onChange={(e) =>
                    setNewUser({ ...newUser, email: e.target.value })
                  }
                  className="mt-1 block w-full rounded-md border border-gray-300 p-2.5 focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              {/* Password field */}
              <div className="mb-4">
                <label
                  htmlFor="newUserPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="flex items-center">
                  <input
                    type="text"
                    id="newUserPassword"
                    name="newUserPassword"
                    value={newUser.password}
                    onChange={(e) =>
                      setNewUser({ ...newUser, password: e.target.value })
                    }
                    className="mt-1 block w-full rounded-md border border-gray-300 p-2.5 focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>
              </div>
              {/* Role field */}
              <div className="mb-4">
                <label
                  htmlFor="newUserRole"
                  className="block text-sm font-medium text-gray-700"
                >
                  Role
                </label>
                <select
                  id="newUserRole"
                  name="newUserRole"
                  value={newUser.role}
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      role: parseInt(e.target.value, 10),
                    })
                  }
                  className="mt-1 mb-4 block w-full rounded-md border border-gray-300 p-2.5 focus:border-blue-500 focus:ring-blue-500"
                  required
                >
                  <option value={1}>Admin</option>
                  <option value={0}>User</option>
                </select>
              </div>
              {/* Submit and Cancel buttons */}
              <div className="flex space-x-4 pt-6">
                <button
                  type="submit"
                  className="rounded-lg bg-brand-500 px-4 py-2 text-white"
                >
                  {loading ? (
                    <ClipLoader size={20} color={"#ffffff"} loading={loading} />
                  ) : (
                    "Add User"
                  )}
                </button>
                <button
                  type="button"
                  className="rounded-lg bg-gray-200  px-4 py-2 text-gray-800"
                  onClick={closeAddUserModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileOverview;
