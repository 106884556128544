import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/dashboard",
    path: "home",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Setting",
    layout: "/dashboard",
    path: "setting",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    // name: "Sign In",
    // layout: "/auth",
    path: "sign-in",
    // icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
];
export default routes;
