import React, { useEffect, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

function Modals({
  closeModal,
  isCameraActive,
  isGpsActive,
  user_id,
  setSubmitSuccess,
}) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [address, setAddress] = useState("");
  const [capturedPhoto, setCapturedPhoto] = useState(null);
  const [description, setDescription] = useState("");
  const [product, setProduct] = useState("");
  const [loclatitude, setLatitude] = useState("");
  const [loclongitude, setlongitude] = useState("");
  const [loading, setLoading] = useState(false);
  const [signature, setSignature] = useState(null);
  const [checksignature, setCheckSignature] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState("");

  const handleSubmit = async () => {
    setLoading(true);

    // Konfigurasi Firebase
    const firebaseConfig = {
      apiKey: "AIzaSyCED-mC5kxoZRv9BHerkWbZo_cwtxJmCOU",
      authDomain: "sehatmurnisejahtera-fd9d2.firebaseapp.com",
      projectId: "sehatmurnisejahtera-fd9d2",
      storageBucket: "sehatmurnisejahtera-fd9d2.appspot.com",
      messagingSenderId: "278921260940",
      appId: "1:278921260940:web:e56e46846de17e2e8a30a9",
    };

    const firebaseApp = initializeApp(firebaseConfig);
    const storage = getStorage(firebaseApp);

    try {
      // 1. Upload foto
      const blob = await fetch(capturedPhoto).then((res) => res.blob());
      const photoFileName = `images/${user_id}_${Date.now()}.jpg`;
      const photoStorageRef = ref(storage, photoFileName);
      const photoUploadTask = uploadBytes(photoStorageRef, blob);

      // Dapatkan URL unduhan setelah unggah selesai
      const photoDownloadURL = await photoUploadTask.then((snapshot) => {
        return getDownloadURL(photoStorageRef);
      });
      // 2. Upload tanda tangan
      if (signatureUrl) {
        const signatureFileName = `signatures/${user_id}_${Date.now()}.png`;
        const signatureStorageRef = ref(storage, signatureFileName);
        const signatureUploadTask = uploadBytes(
          signatureStorageRef,
          signatureUrl
        );
        const signatureDownloadURL = await signatureUploadTask.then(
          (snapshot) => {
            return getDownloadURL(signatureStorageRef);
          }
        );

        if (address && loclatitude && loclongitude) {
          // 3. Kirim data menggunakan Axios
          const response = await axios.post(
            "https://api-sehatmurnisejahtera.vercel.app/api/attendance/submit",
            {
              imageUrl: photoDownloadURL,
              signatureUrl: signatureDownloadURL,
              address: address,
              latitude: loclatitude,
              longitude: loclongitude,
              id_user: user_id,
              description: description,
              product: product,
            }
          );

          if (response.status === 200) {
            toast.success(response.data.message);
            setSubmitSuccess();
            closeModal();
            setLoading(false);
          }
        } else {
          toast.error("Waiting Address First");
          setLoading(false);
        }
      } else {
        toast.error("Signature first");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    const enableGps = async () => {
      if (isGpsActive && navigator.geolocation) {
        try {
          await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              setLatitude(latitude);
              setlongitude(longitude);
              const apiKey = "39c16ccc43b54fb697f38ab5adbcfe4e";
              const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;
              const axiosResponse = await axios.get(apiUrl);
              const data = axiosResponse.data;

              if (data.results.length > 0) {
                const formattedAddress = data.results[0].formatted;
                setAddress(formattedAddress);
              }
            },
            (error) => {
              console.error("Error akses GPS:", error.message);
            }
          );
        } catch (error) {
          console.error("Error akses GPS:", error.message);
        }
      }
    };

    if (isGpsActive) {
      enableGps();
    }

    const enableCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          setVideoDimensions({
            width: videoRef.current.videoWidth,
            height: videoRef.current.videoHeight,
          });
        };
      } catch (error) {
        console.error("Error akses kamera:", error);
      }
    };

    if (isCameraActive) {
      enableCamera();
    }

    return () => {
      if (isCameraActive) {
        const stream = videoRef.current?.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
      }
    };
  }, [isCameraActive, isGpsActive]);

  const takePhoto = async () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (video && canvas) {
      const context = canvas.getContext("2d");
      canvas.width = videoDimensions.width;
      canvas.height = videoDimensions.height;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const dataURL = canvas.toDataURL("image/jpeg", 0.8); // Sesuaikan kualitas jika diperlukan
      setCapturedPhoto(dataURL);

      // Matikan kamera
      if (isCameraActive) {
        const stream = videoRef.current?.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
      }

      // Ubah status kamera menjadi nonaktif
      // setIsCameraActive(false);
    }
  };

  const handleCancel = () => {
    // Matikan kamera
    if (isCameraActive) {
      const stream = videoRef.current?.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    }

    // Tutup modal dan reset state
    closeModal();
    setCapturedPhoto(null);
    setDescription("");
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleProductChange = (e) => {
    setProduct(e.target.value);
  };

  const saveSignature = async () => {
    if (signature) {
      const imageData = signature.toDataURL();
      const blob = await fetch(imageData).then((res) => res.blob());
      setSignatureUrl(blob);
      setCheckSignature(true);
    } else {
      console.error("Tanda tangan belum dibuat");
    }
  };

  useEffect(() => {
    if (!isCameraActive) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, [isCameraActive]);

  useEffect(() => {
    if (!isGpsActive) {
      setAddress("");
    }
  }, [isGpsActive]);

  return (
    <div className="fixed inset-0 z-50 flex  items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="max-h-2xl relative rounded-lg bg-white p-6">
        {checksignature ? (
          <div className="mb-4 text-center">
            <img src={capturedPhoto} alt="Captured" className="rounded-lg" />
          </div>
        ) : isCameraActive && !capturedPhoto ? (
          <div className="relative mb-4">
            <video
              ref={videoRef}
              autoPlay
              playsInline
              className="w-full rounded-lg"
            />
          </div>
        ) : !isCameraActive ? (
          <div className="mb-4 text-center">
            <ClipLoader size={35} color={"#123abc"} />
          </div>
        ) : null}

        {capturedPhoto && !checksignature ? (
          <div className="mb-4 flex max-w-6xl flex-col text-center">
            <h1 className="pb-4 text-base">Signature</h1>
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                className: "signature-canvas",
                style: {
                  border: "2px solid #868CFF",
                  padding: "4px",
                  width: "100%",
                  height: "300px",
                },
              }}
              ref={(ref) => setSignature(ref)}
            />
            <div className="mb-4 mt-4 text-center">
              <button
                type="button"
                className="rounded-full bg-brand-500 py-2 px-4 font-semibold text-white hover:bg-brand-700"
                onClick={saveSignature}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {checksignature ? (
          <>
            <div className="mb-2 flex flex-col gap-2">
              <p className="font-bold">Description</p>
              <input
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Insert Description here.."
                className="w-full rounded border border-brand-500 p-2"
              ></input>
              <p className="pt-2 font-bold">Products Offered</p>
              <input
                value={product}
                onChange={handleProductChange}
                placeholder="Insert Products here.."
                className="w-full rounded border border-brand-500 p-2"
              ></input>
            </div>
            {address ? (
              <div className="mb-4 text-left">
                <p className="text-base">{`Location: ${address}`}</p>
              </div>
            ) : (
              <div className="mb-4 text-left">
                <ClipLoader size={20} color={"#123abc"} />
              </div>
            )}
          </>
        ) : null}

        {isCameraActive && !capturedPhoto ? (
          // Jika kamera aktif dan belum ada foto yang diambil, tampilkan tombol "Ambil Foto"
          <div className="mb-4 text-center">
            <button
              type="button"
              className="rounded-full bg-brand-500 py-2 px-4 font-semibold text-white hover:bg-brand-700"
              onClick={takePhoto}
            >
              Captured
            </button>
          </div>
        ) : null}

        <div className="mb-4 text-center">
          <canvas
            ref={canvasRef}
            className="hidden"
            width={videoDimensions.width}
            height={videoDimensions.height}
          ></canvas>
        </div>
        <div className="mt-6 flex items-center justify-end gap-4">
          {loading ? (
            <ClipLoader
              size={15}
              color={"#123abc"}
              className="inline-flex justify-center"
            />
          ) : null}
          {checksignature ? (
            <button
              className="mt-3 inline-flex w-full justify-center rounded-md bg-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset hover:bg-brand-700 sm:mt-0 sm:w-auto"
              onClick={handleSubmit}
              disabled={loading}
            >
              Submit
            </button>
          ) : null}
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset  hover:bg-brand-700 sm:mt-0 sm:w-auto"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modals;
