import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import auth, { user, setAuth } from "../../../features/auth/auth";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { MdCalendarToday } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import Modals from "components/Modals";
import Widget from "components/widget/Widget";
import Table from "./Table";
import { ClipLoader } from "react-spinners";

const Dashboard = () => {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth.Auth); // Menggunakan useSelector untuk mendapatkan data dari Redux store
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [isGpsActive, setIsGpsActive] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const token = localStorage.getItem("user_token");

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const openModal = () => {
    setIsModalOpen(true);
    setIsCameraActive(true); // Aktifkan kamera secara default
    setIsGpsActive(true); // Aktifkan GPS secara default
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsCameraActive(false); // Nonaktifkan kamera saat modal ditutup
    setIsGpsActive(false); // Nonaktifkan GPS saat modal ditutup
  };

  const fetchDataFromAPI = async () => {
    try {
      setLoading(true);
      const isAdmin = authData?.data?.isAdmin; // Ganti dengan logika untuk menentukan apakah pengguna adalah admin atau tidak
      const user_id = authData?.data?.id; // Ganti dengan user_id pengguna jika bukan admin
      const response = await axios.post(
        "https://api-sehatmurnisejahtera.vercel.app/api/data/",
        {
          isAdmin,
          user_id,
        }
      );
      const responseData = response.data.data;
      if (responseData.lastAttendance !== null) {
        responseData.lastAttendance = formatDate(responseData.lastAttendance);
      }

      // Set data ke dalam state
      setData(responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading menjadi false setelah pengambilan data selesai
    }
  };

  const fetchData = async () => {
    try {
      if (token) {
        await dispatch(user());
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!authData) {
      fetchData();
    }
    if (data.length < 1) {
      fetchDataFromAPI();
    }
  }, [authData.data]);

  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-4">
        {authData?.data?.isAdmin ? (
          <>
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Total Users"}
              subtitle={
                loading ? (
                  <ClipLoader size={20} color={"#123abc"} />
                ) : (
                  data?.totalUsers
                )
              }
            />
            <Widget
              icon={<IoDocuments className="h-6 w-6" />}
              title={"Today Attendance"}
              subtitle={
                loading ? (
                  <ClipLoader size={20} color={"#123abc"} />
                ) : (
                  data?.todayAttendance
                )
              }
            />
            <Widget
              icon={<IoDocuments className="h-6 w-6" />}
              title={"Total Attendance"}
              subtitle={
                loading ? (
                  <ClipLoader size={20} color={"#123abc"} />
                ) : (
                  data?.totalAttendance
                )
              }
            />
            <Widget
              icon={<MdBarChart className="h-6 w-6" />}
              title={"Most Frequent User"}
              subtitle={
                loading ? (
                  <ClipLoader size={20} color={"#123abc"} />
                ) : (
                  data?.mostFrequentUser?.name
                )
              }
            />
          </>
        ) : (
          <>
            <Widget
              icon={<IoDocuments className="h-6 w-6" />}
              title={"Total Attendance"}
              subtitle={
                loading ? (
                  <ClipLoader size={20} color={"#123abc"} />
                ) : (
                  data?.totalAttendance || "-"
                )
              }
            />
            <Widget
              icon={<IoDocuments className="h-6 w-6" />}
              title={"Today Attendance"}
              subtitle={
                loading ? (
                  <ClipLoader size={20} color={"#123abc"} />
                ) : (
                  data?.todayAttendance || "-"
                )
              }
            />
            <Widget
              icon={<MdCalendarToday className="h-6 w-6" />}
              title={"Last Attendance"}
              subtitle={
                loading ? (
                  <ClipLoader size={20} color={"#123abc"} />
                ) : data?.lastAttendance !== null ? (
                  formatDate(data.lastAttendance)
                ) : (
                  "-"
                )
              }
            />

            <Widget
              icon={<MdLocationOn className="h-6 w-6" />}
              title={"Last Location"}
              subtitle={
                loading ? (
                  <ClipLoader size={20} color={"#123abc"} />
                ) : data?.lastLocation ? (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${data?.lastLocation?.latitude},${data?.lastLocation?.longitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-brand-500 hover:underline"
                  >
                    View on Map
                  </a>
                ) : (
                  "-"
                )
              }
            />
          </>
        )}
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        <div>
          <button
            onClick={openModal}
            className="rounded bg-brand-500 py-2 px-4 font-bold text-white hover:bg-brand-700"
          >
            Clock In
          </button>

          {isModalOpen && (
            <Modals
              closeModal={closeModal}
              isCameraActive={isCameraActive}
              isGpsActive={isGpsActive}
              user_id={authData?.data.id}
              setSubmitSuccess={setSubmitSuccess}
            />
          )}
        </div>
      </div>
      <div className="mt-10">
        <Table
          isAdmin={authData?.data?.isAdmin}
          user_id={authData?.data?.id}
          submitSuccess={submitSuccess}
          setSubmitSuccess={setSubmitSuccess}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Dashboard;
