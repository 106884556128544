import React, { useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { user } from "./features/auth/auth";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = localStorage.getItem("user_token");

  const fetchData = async () => {
    try {
      if (userToken) {
        await dispatch(user());
        const decodedToken = jwtDecode(userToken);
        if (
          decodedToken.exp &&
          decodedToken.exp < Math.floor(Date.now() / 1000)
        ) {
          localStorage.removeItem("user_token");
          navigate("/sign-in", { replace: true });
          return;
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // useEffect(() => {
  //   if (userToken) {
  //     dispatch(user());
  //   }
  //   fetchData();
  // }, [userToken, navigate]);

  useEffect(() => {
    if (userToken) {
      dispatch(user());
    }
    fetchData();
    if (!userToken) {
      navigate("/sign-in", { replace: true });
    }
  }, [userToken, navigate]);

  return (
    <Routes>
      {userToken ? (
        <>
          <Route path="/" element={<AdminLayout />} />
          <Route path="dashboard/*" element={<AdminLayout />} />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        </>
      ) : (
        <Route path="/*" element={<AuthLayout />} />
      )}
    </Routes>
  );
};

export default App;
